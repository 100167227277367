import React, { Component } from 'react';

class GameEventDialog extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="dialog-background">
        <div className="easter-dialog">
          <img className="close-dialog" src="/game_event/closed.webp" onClick={this.props.closeDialog} />
          <h2>Gewinne im Wert von über 2.000 €</h2>
          <div className="row">
            <div className="col-md-4">
              <img src="/game_event/easter-rabbit.webp" alt="rabbits" />
            </div>
            <div className="col-md-8">
              <ul>
                <li><img src="/game_event/green-icon1.webp" /><span>Täglich Gutscheine und Noozie Punkte gewinnen</span></li>
                <li>
                  <img src="/game_event/green-icon2.webp" />
                  <span>
                    Sammle bis Aktionsende bis zu 90 Eier und nimm an der Verlosung der Hauptgewinne teil:
                    <ul>
                      <li><strong>1. Preis</strong> - (über 80 gesammelte Eier) - 5 x 3 Monats-Abo Classic Box, 10 x Candy Box, 10 x Candy & Snack Adventskalender</li>
                      <li><strong>2. Preis</strong> - (50 - 79 gesammelte Eier) - 15 x Cool Box Mai, 10 x XXL Deal Mai, 10 x Asia Box</li>
                    </ul>
                  </span>
                </li>
                <li><img src="/game_event/green-icon3.webp" /><span>Aktionszeitraum vom 14.03. - 01.04.2024</span></li>
              </ul>
            </div>
          </div>
          <div>
            <p className="centered">Wir verstecken jeden Tag 5 Ostereier für dich, die du in <a href={this.props.profilePath} target="_blank">deinem Profil</a> in einem virtuellen Osterkörbchen sammeln kannst! *</p>
          </div>
          <div className="centered">
            {
              this.props.signedIn ? <a href="" onClick={this.props.closeDialog} className="btn btn-green">Los geht's!</a> : <a href="/customers/sign_in" className="btn btn-green">Login / Registrierung</a>
            }
          </div>
          <div className="centered easter-footer">* Nur registrierte und eingeloggte Nutzer können Ostereier sammeln.</div>
          <div className="centered easter-footer">Mit deiner Teilnahme erklärst du dich mit unseren <a href="https://brandnooz.de/blog/teilnahmebedingungen-brandnooz-ostergewinnspiel-2024/" target="_blank">Teilnahmebedingungen</a> einverstanden.</div>
        </div>
      </div>
    );
  }
}

export default GameEventDialog;
