import 'core-js/stable';

import ReactOnRails from 'react-on-rails';
import CookieMessage from '../bundles/CookieConfiguration/components/CookieMessage'
import Shop from '../bundles/ShoppingCart/components/ShoppingCart/Shop';
import OrderProcess from '../bundles/ShoppingCart/components/OrderProcess/OrderProcess';
import GameEvent from '../bundles/GameEvent/components/GameEvent.jsx';

// This is how react_on_rails can see the Shop in the browser.
ReactOnRails.register({
  CookieMessage,
  Shop,
  OrderProcess,
  GameEvent
});
