import React, { Component } from 'react';
import CartOverview from './CartOverview';
import AddressStep from './AddressStep';
import PaymentStep from './PaymentStep';
import GiftStep from './GiftStep';
import ConfirmationStep from './ConfirmationStep';
import Stepper from './Stepper';
import { reportVirtualPageRequest } from '../../utilities/google_analytics_helpers';

class OrderProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      maxStep: 1,
      cart: {
        guest: true,
        exclude_alcohol: false,
        includes_gift: false,
        items: [],
        payment_required: true,
        same: false
      },
      addresses: [],
      paymentProfiles: {
        credit_card: [],
        paypal: []
      },
      paymentProfile: {}
    }
  }

  componentDidMount = () => {
    $.get('/bestellung.json').done( (data) => {
      if (data.cart.step && data.cart.step > 2) {
        if (data.cart.step > 3) {
          this.resetPaymentProfile()
        }
        this.resetAddress()
      }
      this.setState({
        cart: data.cart,
        step: data.cart.step || 1,
        maxStep: data.cart.step
      })
    })
  }

  resetAddress = () => {
    $.get('/bestellung/addresses.json').done( (data) => {
      this.updateAddress(data)
    })
  }

  addAddress = (attr) => {
    return (address) => {
      this.setState({[attr]: address})
    }
  }

  addressesIdentical = (delivery_address, invoice_address) => {
    if (delivery_address != null) {
      if (invoice_address == null) {
        return false
      } else if (delivery_address.id != invoice_address.id) {
        return false
      }
    } else if (invoice_address != null) {
      return false
    }
    return true
  }

  updateAddress = (data) => {
    this.setState(
      {
        addresses: data.addresses,
        deliveryAddress: data.delivery_address,
        invoiceAddress: data.invoice_address
      }
    )
  }

  resetPaymentProfile = () => {
    $.get('/bestellung/payment_profiles.json').done( (data) => {
      this.updatePaymentProfile(data)
    })
  }

  updatePaymentProfile = (data) => {
    this.setState(
      {
        paymentProfiles: data.payment_profiles,
        paymentProfile: data.payment_profile
      }
    )
  }

  updateCart = (cart) => {
    const newCart = {...this.state.cart,...cart}
    this.setState({cart: newCart})
  }

  updateItem = (id) => {
    return (attr) => {
      return (value) => {
        const number = Math.random()
        window.updating = number
        let items = [...this.state.cart.items]
        const index = items.findIndex((item) => item.id == id)
        items[index] = {...items[index], ...{[attr]: value}}
        this.updateCart({items: items})
        $.ajax({
          url: `/order_items/${id}`,
          type: "PATCH",
          data: {
            order_item: {
              [attr]: value
            }
          }
        }).done((data) => {
          if (window.updating == number && items[index].errors) {
            items[index].errors = {}
            this.updateCart({items: items})
          }
        }).fail((data) => {
          let cart = {...this.state.cart}
          const errorItem = {...cart.items[index], ...{errors: data.responseJSON.errors}}
          cart.items[index] = errorItem
          this.setState({cart: cart})
        })
      }
    }
  }

  saveCart = (attr) => {
    return (value) => {
      return (event, callback) => {
        if (event) event.preventDefault()
        $.ajax({
          url: `/bestellung`,
          type: "PATCH",
          data: {
            order: {
              [attr]: value
            }
          }
        }).done((data) => {
          this.updateCart(data.cart)
          if (callback) {
            callback()
          }
        })
      }
    }
  }

  submitCart = (event) => {
    event.preventDefault()
    $.ajax({
      url: `/bestellung/bestätigen`,
      type: "PUT"
    }).fail((data) => {
      toastr.error(data.responseJSON.errors.join(' '));
    })
  }

  scrollUp = () => {
    $('html, body').animate({
      scrollTop: $('#order-process').offset().top
    }, 500);
  }

  setStep = (step) => {
    return (event) => {
      event.preventDefault()
      if (step > this.state.maxStep) {
        this.setState({step: step, maxStep: step})
        this.saveCart('step')(step)(event, this.scrollUp)
      } else {
        this.setState({step: step}, this.scrollUp)
      }
    }
  }

  toastr = (message) => {
    return (event) => {
      event.preventDefault()
      toastr.error(message)
    }
  }

  pushDataLayer = (step) => {
    return () => {
      dataLayer.push({
        event: 'eec.checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: step
            }
          }
        }
      })
    }
  }

  renderStep = () => {
    if (this.state.step <= 1) {
      return <CartOverview cart={this.state.cart}
                           saveCart={this.saveCart}
                           setStep={this.setStep}
                           toastr={this.toastr}
                           updateCart={this.updateCart}
                           updateItem={this.updateItem}
                           pushDataLayer={this.pushDataLayer(2)}
                           reportVirtualPageRequest={reportVirtualPageRequest(2)} />
    }
    if (this.state.step == 2) {
      return <AddressStep addresses={this.state.addresses}
                          addAddress={this.addAddress}
                          cart={this.state.cart}
                          deliveryAddress={this.state.deliveryAddress}
                          invoiceAddress={this.state.invoiceAddress}
                          reset={this.resetAddress}
                          same={this.state.cart.same}
                          saveCart={this.saveCart}
                          setStep={this.setStep}
                          toastr={this.toastr}
                          update={this.updateAddress}
                          updateCart={this.updateCart}
                          updateItem={this.updateItem}
                          pushDataLayer={this.pushDataLayer(3)}
                          reportVirtualPageRequest={reportVirtualPageRequest(3)} />
    }
    if (this.state.step == 3 && this.state.cart.payment_required) {
      return <PaymentStep cart={this.state.cart}
                          paymentProfile={this.state.paymentProfile}
                          paymentProfiles={this.state.paymentProfiles}
                          reset={this.resetPaymentProfile}
                          saveCart={this.saveCart}
                          setStep={this.setStep}
                          toastr={this.toastr}
                          update={this.updatePaymentProfile}
                          updateCart={this.updateCart}
                          updateItem={this.updateItem} 
                          pushDataLayer={this.pushDataLayer(4)}
                          reportVirtualPageRequest={reportVirtualPageRequest(4)} />
    }
    if (this.state.step == 4 && this.state.cart.includes_gift) {
      return <GiftStep cart={this.state.cart}
                       invoiceAddress={this.state.invoiceAddress}
                       saveCart={this.saveCart}
                       setStep={this.setStep}
                       toastr={this.toastr}
                       updateCart={this.updateCart}
                       updateItem={this.updateItem} />
    }
    if ((this.state.step == 3 && !this.state.cart.payment_required) || (this.state.step == 4 && !this.state.cart.includes_gift) || this.state.step > 4) {
      return <ConfirmationStep cart={this.state.cart}
                               deliveryAddress={this.state.deliveryAddress}
                               invoiceAddress={this.state.invoiceAddress}
                               paymentProfile={this.state.paymentProfile}
                               submitCart={this.submitCart}
                               toastr={this.toastr}
                               updateCart={this.updateCart}
                               pushDataLayer={this.pushDataLayer(5)}
                               reportVirtualPageRequest={reportVirtualPageRequest(5)} />
    }
  }

  render() {
    return (
      <div id="order-process">
        <Stepper step={this.state.step}
                 giftIncluded={this.state.cart.includes_gift}
                 paymentRequired={this.state.cart.payment_required}
                 maxStep={this.state.maxStep}
                 setStep={this.setStep}/>
        { this.state.step > 1 ? <div className="mobile"><a className="btn btn-primary" onClick={this.setStep(this.state.step - 1)}>Zurück</a></div> : "" }
        {this.renderStep()}
      </div>
    );
  }
}

export default OrderProcess;
