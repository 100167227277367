import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaymentProfile from './PaymentStep/PaymentProfile'
import ReactTooltip from 'react-tooltip';

class PaymentStep extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this.props.reset()
    this.props.reportVirtualPageRequest()
  }


  requirementsFullfilled = () => {
    return this.props.cart.payment_profile_id
  }

  choose = (paymentProfileId) => {
    return (event) => {
      this.props.saveCart('payment_profile_id')(paymentProfileId)(event, this.props.reset)
    }
  }

  delete = (paymentProfileId) => {
    return (event) => {
      event.preventDefault()
      event.stopPropagation()
      if (paymentProfileId) {
        $.ajax({
          url: `/bestellung/payment_profiles/${paymentProfileId}.json`,
          type: "DELETE"
        }).done((data) => {
          this.props.update(data)
          if (this.props.cart.payment_profile_id == paymentProfileId) {
            this.props.updateCart({ payment_profile_id: null })
          }
        })
      }
    }
  }

  renderPurchaseOnAccount = () => {
    if (this.props.paymentProfiles.purchase_on_account) {
      return <div>
        <h5 className="align-left">Kauf auf Rechnung</h5>
        <div className="row">
          <div className="col-md-4">
            <div className="card add">
              <a href={`/orders/${this.props.cart.id}/rechnungskauf/new`} data-toggle="modal" data-target="#modal-ajax" >
                <i className="fas fa-plus" />
                Rechnungskauf bestätigen
              </a>
            </div>
          </div>
        </div>
      </div>

    } else {
      return <div>
        <h5 className="align-left">Rechnungskauf?</h5>
        <div>
          Du möchtest per Rechnungskauf bezahlen? Leider können wir diese Zahlungsmöglichkeit aktuell nur für .
        </div>
      </div>
    }
  }

  renderSepa = () => {
    if (this.props.paymentProfiles.sepa) {
      return <div>
        <h5 className="align-left">Deine SEPA-Mandate</h5>
        <div className="row">
          {
            this.props.paymentProfiles.sepa.map((paymentProfile) => {
              return <PaymentProfile key={`payment-profile-${paymentProfile.id}`}
                paymentProfile={paymentProfile}
                delete={this.delete(paymentProfile.id)}
                choose={this.choose(paymentProfile.id)}
                selected={this.props.paymentProfile && this.props.paymentProfile.id == paymentProfile.id} />
            })
          }
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card add">
              <a href={`/orders/${this.props.cart.id}/sepa/new`} data-toggle="modal" data-target="#modal-ajax" >
                <i className="fas fa-plus" />
                Neues SEPA Lastschriftmandat einrichten
              </a>
            </div>
          </div>
        </div>
      </div>
    } else {
      return <div>
        <h5 className="align-left">SEPA?</h5>
        <div>
          Du möchtest per SEPA bezahlen? Wende dich gerne an unseren Kundenservice, um diese Zahlungsmethode für dich freizuschalten.
        </div>
      </div>
    }
  }

  submit = (event) => {
    this.props.pushDataLayer()
    this.props.setStep(4)(event)
  }

  render() {
    return (
      <div>
        <h4 className="align-left">Zahlungsart wählen</h4>
        <div>
          <h5 className="align-left">Deine Kreditkarten</h5>
          <div className="row">
            {
              this.props.paymentProfiles.credit_card.map((paymentProfile) => {
                return <PaymentProfile key={`payment-profile-${paymentProfile.id}`}
                  paymentProfile={paymentProfile}
                  delete={this.delete(paymentProfile.id)}
                  choose={this.choose(paymentProfile.id)}
                  selected={this.props.paymentProfile && this.props.paymentProfile.id == paymentProfile.id} />
              })
            }
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card add">
                <a href={`/orders/${this.props.cart.id}/credit_cards/new`} data-toggle="modal" data-target="#modal-ajax" >
                  <i className="fas fa-plus" />
                  Neue Kreditkarte angeben
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h5 className="align-left">Deine Paypal-Konten</h5>
          <div className="row">
            {
              this.props.paymentProfiles.paypal.map((paymentProfile) => {
                return <PaymentProfile key={`payment-profile-${paymentProfile.id}`}
                  paymentProfile={paymentProfile}
                  delete={this.delete(paymentProfile.id)}
                  choose={this.choose(paymentProfile.id)}
                  selected={this.props.paymentProfile && this.props.paymentProfile.id == paymentProfile.id} />
              })
            }
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card add">
                <a href={`/orders/${this.props.cart.id}/paypal/new`} data-toggle="modal" data-target="#modal-ajax" >
                  <i className="fas fa-plus" />
                  Paypal Konto hinzufügen
                </a>
              </div>
            </div>
          </div>

        </div>
        {this.renderPurchaseOnAccount()}
        {this.renderSepa()}
        <div className="bottom center">
          {
            this.requirementsFullfilled() ?
              <a href='#' onClick={this.submit} className="btn btn-green" >Weiter</a> :
              <React.Fragment>
                <a href='#' onClick={this.props.toastr('Bitte eine Zahlungsart auswählen')} className="btn btn-invalid" data-tip="Bitte eine Zahlungsart auswählen" >Weiter</a>
                <ReactTooltip />
              </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

PaymentStep.propTypes = {
  cart: PropTypes.object.isRequired,
  paymentProfile: PropTypes.object.isRequired,
  paymentProfiles: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  saveCart: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
}

export default PaymentStep;
