function totalPrice(items, subscriberDiscounts) {
  return items.reduce((sum, item) => {
    return sum + priceForItem(item, subscriberDiscounts) * item.count;
  }, 0);
}

export function applySubscriberPrice(item, subscriberDiscounts) {
  if (!item.allow_subscriber_discount) {
    return false;
  }
  if (!item.is_subscription && subscriberDiscounts.singleBoxes) {
    return true;
  } else {
    return subscriberDiscounts.allBoxes;
  }
}

function priceForItem(item, subscriberDiscounts) {
  if (applySubscriberPrice(item, subscriberDiscounts)) {
    return item.subscriber_price_cents;
  } else {
    return item.price_cents_without_subscriber_discount;
  }
}

export default totalPrice;
