import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Addresses from './AddressStep/Addresses'
import CheckmarkSVG from './../../images/checkmark.svg';
import ReactTooltip from 'react-tooltip';

class AddressStep extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this.props.reset()
    this.props.reportVirtualPageRequest()
  }

  requirementsFullfilled = () => {
    return this.props.cart.invoice_address_id != null && (!this.props.cart.delivery_address_required || this.props.cart.delivery_address_id != null)
  }

  preventDefault = (event) => {
    event.preventDefault()
  }

  toggleInvoiceAddress = (event) => {
    this.props.saveCart('same')(!this.props.same)(event, this.props.reset)
  }

  chooseAddress = (attribute) => {
    return (addressId) => {
      return (event) => {
        if (this.props.same) {
          this.props.saveCart('invoice_address_id')(addressId)(event)
          this.props.saveCart('delivery_address_id')(addressId)(event, this.props.reset)
        } else {
          this.props.saveCart(attribute)(addressId)(event, this.props.reset)
        }
      }
    }
  }

  markErrors = (addressType) => {
    return (address) => {
      return (data) => {
        if (address.id) {
          let addresses = [...this.props.addresses]
          const index = addresses.findIndex((item) => item.id == address.id)
          const markedAddress = {...this.props.address[index], ...{errors: data.responseJSON.errors}}
          addresses[index] = markedAddress
          this.props.update({addresses: addresses, deliveryAddress: this.props.delivery_address, invoice_address: this.props.invoiceAddress})
        } else {
          const markedAddress = {...this.props[addressType], ...{errors: data.responseJSON.errors}}
          this.props.update({...{addresses: this.props.addresses, delivery_address: this.props.deliveryAddress, invoice_address: this.props.invoiceAddress},...{[addressType]: markedAddress}})
        }
      }
    }
  }

  renderDeliveryAddresses = () => {
    let addresses = (this.props.deliveryAddress && this.props.same) ? [this.props.deliveryAddress] : this.props.addresses
    return  <React.Fragment>
              <p>Bitte gib hier an, wohin die Boxen mit der Auswahl "Direkt liefern" geliefert werden sollen.</p>
              <p>
                <label>
                  <input type="checkbox"
                         checked={this.props.same}
                         onChange={this.toggleInvoiceAddress} />
                  <span><CheckmarkSVG /></span>
                  Die Lieferadresse entspricht der Rechnungsadresse
                </label>
              </p>
              <Addresses addAddress={this.props.addAddress('deliveryAddress')}
                         address={this.props.deliveryAddress}
                         addresses={addresses}
                         choose={this.chooseAddress('delivery_address_id')}
                         disabled={this.props.same}
                         editable={false}
                         guest={this.props.cart.guest}
                         markErrors={this.markErrors('delivery_address')}
                         reset={this.props.reset}
                         update={this.props.update}
                         withParcelStations={this.props.cart.parcel_station_possible} />
             </React.Fragment>
  }

  renderDeliveryAddressSubstitute = () => {
    if (this.props.cart.delivery_address_notes) {
      return <p>{this.props.cart.delivery_address_notes}</p>
    } else {
      return <p>Nur Gutscheine im Warenkorb - Versand über E-Mail, bzw. dein Profil</p>
    }
  }

  submit = (event) => {
    this.props.pushDataLayer()
    this.props.setStep(3)(event)
  }

  render() {
    return (
      <div>
        <h4 className="align-left">Adresse angeben</h4>
        <h5 className="align-left">Rechnungsadresse</h5>
        <p>Bitte gib hier an, auf welche Adresse wir die Rechnung ausstellen sollen.</p>
        <Addresses addAddress={this.props.addAddress('invoiceAddress')}
                   address={this.props.invoiceAddress}
                   addresses={this.props.addresses}
                   choose={this.chooseAddress('invoice_address_id')}
                   editable={true}
                   guest={this.props.cart.guest}
                   markErrors={this.markErrors('invoice_address')}
                   reset={this.props.reset}
                   update={this.props.update}
                   withParcelStations={false} />
        <h5 className="align-left">Lieferadresse</h5>
        { this.renderDeliveryAddressSubstitute() }
        { this.props.cart.delivery_address_required ? this.renderDeliveryAddresses() : null }
        <div className="bottom center">
          {
            this.requirementsFullfilled() ?
              <a href='#' onClick={this.submit} className="btn btn-green" >Weiter</a> :
              <React.Fragment>
                <a href='#' onClick={this.props.toastr("Bitte erst eine Lieferadresse auswählen")} className="btn btn-invalid" data-tip="Bitte erst eine Lieferadresse auswählen" >Weiter</a>
                <ReactTooltip />
              </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

AddressStep.propTypes = {
  addresses: PropTypes.array.isRequired,
  addAddress: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  deliveryAddress: PropTypes.object,
  invoiceAddress: PropTypes.object,
  reset: PropTypes.func.isRequired,
  same: PropTypes.bool,
  saveCart: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired
}

export default AddressStep;
