import React, { Component } from 'react';
import ShoppingCartSidebar from './ShoppingCartSidebar';
import AddToCartButton from './AddToCartButton';
import CheckoutNowButton from './CheckoutNowButton';
import DynamicBuyCheckoutButton from './DynamicBuyCheckoutButton';
import DynamicGiftCheckoutButton from './DynamicGiftCheckoutButton';

class SidebarLayout extends Component {

  constructor(props) {
    super(props)
  }

  addToCartButtons() {
    let buttons = document.getElementsByClassName("add-to-shopping-cart");
    return Array.prototype.map.call(buttons, (button, index) => {
      return (
        <AddToCartButton
          element={button}
          key={index}
          putIntoShoppingCart={this.props.putIntoShoppingCart}
          offer={JSON.parse(button.dataset.offer)}
          buttontype={button.dataset.buttontype}
        />
      );
    });
  }

  checkoutNowButtons() {
    let buttons = document.getElementsByClassName("checkout-now");
    return Array.prototype.map.call(buttons, (button, index) => {
      return (
        <CheckoutNowButton
          element={button}
          key={index}
          checkoutNow={this.props.checkoutNow}
          offer={JSON.parse(button.dataset.offer)}
        />
      );
    });
  }

  dynamicBuyCheckoutButtons() {
    let buttons = document.getElementsByClassName("dynamic-buy-checkout");
    return Array.prototype.map.call(buttons, (button, index) => {
      return (
        <DynamicBuyCheckoutButton
          element={button}
          key={index}
          checkoutNow={this.props.checkoutNow}
          putIntoShoppingCart={this.props.putIntoShoppingCart}
          toCheckout={this.props.offerIsInCart(JSON.parse(button.dataset.offer))}
          offer={JSON.parse(button.dataset.offer)}
          className={button.dataset.classname}
        />
      );
    });
  }

  dynamicGiftCheckoutButtons() {
    let buttons = document.getElementsByClassName("dynamic-gift-checkout");
    return Array.prototype.map.call(buttons, (button, index) => {
      return (
        <DynamicGiftCheckoutButton
          element={button}
          key={index}
          checkoutNow={this.props.checkoutNow}
          putIntoShoppingCart={this.props.putIntoShoppingCart}
          toCheckout={this.props.offerIsInCart(JSON.parse(button.dataset.offer))}
          offer={JSON.parse(button.dataset.offer)}
          className={button.dataset.classname}
        />
      );
    });
  }

  render() {
    return (
      <React.Fragment>
        <ShoppingCartSidebar
          onOfferRemove={this.props.removeOfferFromCart}
          offers={this.props.cart}
          open={this.props.openSidebar}
          updateItem={this.props.updateItem}
          toggleBody={ this.props.toggleBody }
        />
        {this.addToCartButtons()}
        {this.checkoutNowButtons()}
        {this.dynamicBuyCheckoutButtons()}
        {this.dynamicGiftCheckoutButtons()}
      </React.Fragment>
    )
  }
}

export default SidebarLayout;
