import React, { Component } from 'react'
import ReactDOM from 'react-dom'

export default class DynamicBuyCheckoutButton extends Component {
  constructor(props) {
    super(props)
    var button = this
    if(window.location.hash == '#intoCart' && props.offer.main) {
      window.setTimeout(function () {
        button.handleClick()
      }, 200);
    }
  }

  handleClick = (e) => {
    this.props.putIntoShoppingCart(this.props.offer)
  }

  renderAddToCartButton() {
    return (
      <a onClick={this.handleClick} className={"btn ".concat(this.props.className)} rel="nofollow">
        <i className="fa fa-cart-plus"></i>
        &nbsp;
        Warenkorb
      </a>
    );
  }

  render() {
    return ReactDOM.createPortal(this.renderAddToCartButton(), this.props.element)
  }
}
