import React, { Component } from 'react';
import FormattedDiscount from './FormattedDiscount';
import FormattedMoney from './FormattedMoney';
import { applySubscriberPrice } from '../../utilities/shop_calculations';
import SubscriberDiscountContext from './SubscriberDiscountContext';

export default class Price extends Component {
  constructor(props) {
    super(props);
  }

  subscriberpriceCents = () => this.props.offer.subscriber_price_cents * this.props.offer.count;
  priceCents = () => this.props.offer.price_cents * this.props.offer.count;
  priceCentsWithoutSubscriberDiscount = () => this.props.offer.price_cents_without_subscriber_discount * this.props.offer.count;
  monthlypriceCents = () => this.props.offer.monthly_price_cents * this.props.offer.count;
  monthlySubscriberpriceCents = () => this.props.offer.monthly_subscriber_price_cents * this.props.offer.count;
  subscriberDiscountExists = () => this.subscriberpriceCents() < this.priceCents();

  renderSubscriberPrice = (subscriberPriceApplicable) => {
    if (subscriberPriceApplicable) {
      return <>
        <span className="item-info">einmalig&nbsp;<s><FormattedMoney priceCents={this.priceCents()} /></s></span><br />
        <span className="item-info">Als Abonnent zahlst du nur&nbsp;</span><FormattedMoney priceCents={this.subscriberpriceCents()} />
      </>
    } else {
      return <>
        <span className="item-info">einmalig&nbsp;</span>
        <FormattedMoney priceCents={this.priceCentsWithoutSubscriberDiscount()} /><br />
        <span className="item-info">Als Abonnent würdest du nur <FormattedMoney priceCents={this.subscriberpriceCents()} /> zahlen</span>
      </>
    }
  }

  renderMonthlySubscriberPrice = (subscriberPriceApplicable) => {
    if (subscriberPriceApplicable) {
      return <>
        <span className="item-info">
          <s>danach monatlich &nbsp;
            <FormattedMoney priceCents={this.monthlypriceCents()} /></s><br />
          Als Abonnent zahlst du danach monatlich nur &nbsp;
          <FormattedMoney priceCents={this.monthlySubscriberpriceCents()} />
        </span>
      </>
    } else {
      return <>
        <span className="item-info">
          danach monatlich &nbsp;
          <FormattedMoney priceCents={this.monthlypriceCents()} /><br />
          Als Abonnent würdest du nur&nbsp;<FormattedMoney priceCents={this.monthlySubscriberpriceCents()} /> zahlen
        </span>
      </>
    }
  }

  renderStandardPrices = () => {
    return <>
      <span className="item-info">{this.props.offer.box_frequency} </span>
      <FormattedMoney priceCents={this.priceCents()} />
    </>
  }

  renderMonthlyPrice = () => {
    return <span className="item-info">
      danach monatlich &nbsp;
      <FormattedMoney priceCents={this.monthlypriceCents()} />
    </span>
  }

  renderFirstBoxPrices = (subscriberPriceApplicable) => {
    return <>
      <p>
        <span className="item-info">einmalig </span>
        {applySubscriberPrice(this.props.offer, subscriberPriceApplicable) ? this.renderSubscriberPrice(subscriberDscountApplicable) : <FormattedMoney priceCents={this.priceCents()} />}
      </p>
      {applySubscriberPrice(this.props.offer, subscriberDscountApplicable) ? this.renderMonthlySubscriberPrice() : this.renderMonthlyPrice()}
      <p>
      </p>
    </>
  }

  renderWithDiscountInformation = (subscriberPriceApplicable) => {
    console.log(this.props.offer)
    if (this.subscriberDiscountExists()) {
      if (this.props.offer.is_subscription) {
        if (this.props.offer.discount_applies_to_first_month_only) {
          return <>
            {this.renderSubscriberPrice(subscriberPriceApplicable)}<br />
            {this.renderMonthlySubscriberPrice(subscriberPriceApplicable)}
          </>
        } else {
          return this.renderSubscriberPrice(subscriberPriceApplicable)
        }
      } else {
        return <>
          {this.renderSubscriberPrice(subscriberPriceApplicable)}
        </>
      }
    } else {
      if (this.props.offer.is_subscription && this.props.offer.discount_applies_to_first_month_only) {
        return <>
          {this.renderStandardPrices()}<br />
          {this.renderMonthlyPrice()}
        </>
      } else {
        return this.renderStandardPrices()
      }
    }

    this.props.offer.discount_applies_to_first_month_only ? this.renderFirstBoxPrices() : this.renderStandardPrices(applySubscriberPrice(this.props.offer, subscriberDiscounts))
  }
  render() {
    return (
      <>
        <SubscriberDiscountContext.Consumer>
          {(subscriberDiscounts) => this.renderWithDiscountInformation(applySubscriberPrice(this.props.offer, subscriberDiscounts))}
        </SubscriberDiscountContext.Consumer>
        {this.props.offer.is_prime_discounted && <div class="item-info">Dein Prime Rabatt ist bereits enthalten.</div>}
      </>
    );
  }
}
