import React, { Component } from 'react';
import Switch from 'react-switch';

class CookieConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketing: this.props.configuration.includes('marketing')
    }
  }

  save = (event) => {
    if (this.state.marketing) {
      this.props.allowAllCookies(event)
    } else {
      this.props.allowEssentialCookies(event)
    }
  }

  handleChange = (attribute) => {
    return (checked) => {
      this.setState({[attribute]: checked})
    }
  }

  render() {
    return(
      <div>
        <h4>Cookie Verwaltung</h4>
        <label className="right">
          Immer aktiv
        </label>
        <h5>Essenziell</h5>
        <p>
          Diese Cookies sind für die Funktionen der brandnooz Website unverzichtbar und sorgen zum Beispiel dafür, dass
          du angemeldet bleibst wenn du auf brandnooz.de surfst. Sie unterstützen die Bereitstellung von grundlegenden
          Funktionen und können daher nicht deaktiviert werden.
        </p>

        <label className="right">
          { this.state.marketing ? 'An' : 'Aus'}
          <Switch onChange={this.handleChange('marketing')}
                  checked={this.state.marketing}
                  onColor="#5DCC79"
                  offColor="#A7A7A7"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={40} />
        </label>
        <h5>Marketing analytics</h5>
        <p>
          Diese Cookies erfassen Informationen darüber, wie du auf uns aufmerksam geworden bist und welche brandnooz
          Produkte dich interessieren. Dadurch können wir unser Angebot stetig verbessern. Wir geben diese Daten an
          Drittanbieter wie Google, Facebook und Awin weiter.
        </p>
        <div>
          <a href="#" className="btn btn-highlight" onClick={this.props.allowAllCookies}>
            Alle Cookies akzeptieren
          </a>
        </div>
        <div>
          <a href="#" className="btn btn-primary" onClick={this.save}>
            Speichern
          </a>
        </div>
      </div>
    )
  }
}

export default CookieConfiguration;
