import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormattedAddress from './FormattedAddress';

class Address extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.address.line1}<br/>
        { this.props.address.is_parcel_station ? this.props.address.line3 : this.props.address.line2}<br/>
        { this.props.address.is_parcel_station ? `Postnummer: ${this.props.address.line2}` : this.props.address.line3 }<br/>
        { this.props.address.line4 }<br/>
        { this.props.address.country === 'DE' ? 'Deutschland' : 'Österreich' }
      </React.Fragment>
    );
  }
}

Address.propTypes = {
  address: PropTypes.object.isRequired
}

export default Address;
