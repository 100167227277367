import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class AddToCartButton extends Component {
  constructor(props) {
    super(props);
    while (this.props.element.firstChild) {
      this.props.element.removeChild(this.props.element.firstChild);
    }
  }

  putIntoShoppingCart = () => {
    this.props.putIntoShoppingCart(this.props.offer);
  }

  renderTextButton() {
    return (
      <a className="btn btn-success" onClick={this.putIntoShoppingCart}>In den Warenkorb</a>
    );
  }

  renderIconButton() {
    return (
      <a onClick={this.putIntoShoppingCart} className="order-now-icon-button btn btn-success" title="In den Warenkorb legen und weitershoppen!">
        <span>
          <i className="fa fa-cart-plus fa-2x"></i>
        </span>
      </a>
    );
  }

  render() {
    let button = this.props.buttontype === "icon" ? this.renderIconButton() : this.renderTextButton();
    return ReactDOM.createPortal(button, this.props.element);
  }
}
