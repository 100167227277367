import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CartItem from './CartOverview/CartItem';
import ShippingCosts from './CartOverview/ShippingCosts';
import CheckmarkSVG from './../../images/checkmark.svg';
import ReactTooltip from 'react-tooltip';

class CartOverview extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this.props.reportVirtualPageRequest()
  }

  requirementsFullfilled = () => {
    return (this.props.cart.items.length > 0 && !this.anyDateUnselected())
  }

  validationMessage = () => {
    if (this.props.cart.items.length == 0) {
      return "Ihr Warenkorb ist leer"
    }
    if (this.anyDateUnselected()) {
      return 'Bitte erst ein Lieferdatum auswählen.'
    }
  }

  anyDateUnselected = () => {
    let unselected = false
    this.props.cart.items.forEach((item) => {
      if (item.delivery_date_selectable && !item.delivery_on) {
        unselected = true
      }
    })
    return unselected
  }

  duplicateItem = (id) => {
    return (event) => {
      event.preventDefault()
      $.post(`/order_items/${id}/duplicate`).done((data) => {
        this.props.updateCart(data.cart)
      })
    }
  }

  removeItem = (id) => {
    return (event) => {
      event.preventDefault()
      $.ajax({
        url: `/order_items/${id}`,
        type: "DELETE"
      }).done((data) => {
        this.props.updateCart(data.cart)
      })
    }
  }

  updateShipWithinSubscription = (subscription) => {
    if (subscription) {
      this.props.saveCart('ship_within_subscription_id')(subscription.value)()
    } else {
      this.props.saveCart('ship_within_subscription_id')(null)()
    }
  }

  submit = (event) => {
    this.props.pushDataLayer()
    this.props.setStep(2)(event)
  }

  render() {
    return (
      <div id="cart-overview">
        <h4 className="align-left">Warenkorb</h4>
        <table className="table responsive">
          <thead>
            <tr>
              <th>Artikel</th>
              <th>Lieferung</th>
              <th>Versand</th>
              <th />
              <th>Preis</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.cart.items.map((item) => <CartItem key={`cartItem${item.id}`}
              item={item}
              deleteItem={this.removeItem(item.id)}
              duplicateItem={this.duplicateItem(item.id)}
              updateItem={this.props.updateItem(item.id)} />)}
          </tbody>
          <tfoot>
            <tr>
              <td className="no-border only" colSpan="2">
                <div>
                  {
                    this.props.cart.alcohol_substitutable ?
                      <label>
                        <input type="checkbox" checked={this.props.cart.exclude_alcohol} onChange={this.props.saveCart('exclude_alcohol')(!this.props.cart.exclude_alcohol)} />
                        <span><CheckmarkSVG /></span>
                        {this.props.cart.substitude_alcohol_description}
                      </label> : null
                  }
                </div>
              </td>
              <td colSpan="2">
                Zwischensumme
              </td>
              <td>
                {this.props.cart.price_before_shipping}
              </td>
            </tr>
            <ShippingCosts cart={this.props.cart} updateShipWithinSubscription={this.updateShipWithinSubscription} />
            <tr>
              <td className="no-border foot no-mobile" colSpan="2" />
              <td colSpan="2">
                Gesamtsumme
              </td>
              <td>{this.props.cart.price}</td>
            </tr>
            {
              this.props.cart.noozie_points > 0 ?
                <tr>
                  <td className="no-border no-mobile" />
                  <td>
                    Verdiente Noozie Punkte
                  </td>
                  <td>
                    {this.props.cart.noozie_points}
                  </td>
                </tr> : null
            }
          </tfoot>
        </table>

        <div className="bottom right">
          {
            this.requirementsFullfilled() ?
              <a href='#' onClick={this.submit} className="btn btn-green" >Zur Kasse</a> :
              <React.Fragment>
                <a href='#' onClick={this.props.toastr(this.validationMessage())} className="btn btn-invalid" data-tip={this.validationMessage()} >Zur Kasse</a>
                <ReactTooltip />
              </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

CartOverview.propTypes = {
  cart: PropTypes.object.isRequired,
  saveCart: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired
}

export default CartOverview;
