import React, { Component } from 'react';
import FormattedMoney from './FormattedMoney';
import FormattedDiscount from './FormattedDiscount';
import Price from './Price';
import { applySubscriberPrice } from '../../utilities/shop_calculations';
import { removeCartTracking } from '../../utilities/google_analytics_helpers';
import SubscriberDiscountContext from './SubscriberDiscountContext';

class CartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bigCounter: this.props.offer.count >= 10
    }
  }

  removeItem = () => {
    this.props.onOfferRemove(this.props.offer);
    removeCartTracking(this.props.offer);
  }

  onCounterChangeHandler = (e) => {
    let count = parseInt(e.target.value)
    if (!count) {
      count = 0
    }
    this.props.updateItem({ count: count })
  }

  renderCounter = (count) => {
    if (this.props.offer.is_subscription || this.props.offer.voucher) {
      return this.props.offer.count
    } else {
      if (this.state.bigCounter) {
        return <input onChange={this.onCounterChangeHandler} value={this.props.offer.count} type="number" />
      } else {
        return <select className='selectpicker' onChange={this.onCounterChangeHandler} value={this.props.offer.count}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(count => <option key={`option_${count}`} value={count}>{count}</option>)}
          <option value="10">10+</option>
        </select>
      }
    }
  }

  componentDidMount = () => {
    $('.cart-item-wrap .selectpicker').selectpicker()
  }

  componentDidUpdate = () => {
    $('.cart-item-wrap .selectpicker').selectpicker('destroy').addClass('selectpicker')
    if (this.props.offer.count >= 10 && !this.state.bigCounter) {
      this.setState({ bigCounter: true })
    }
    window.setTimeout((() => $('.cart-item-wrap .selectpicker').selectpicker()), 20)
  }

  componentWillUnmount = () => {
    $('.cart-item-wrap .selectpicker').selectpicker('destroy').addClass('selectpicker')
  }

  render() {
    let orderItem = null;
    let subscriberDiscountInfo = null;
    if (this.props.offer.is_subscription) {
      const startingVersion = ` (${this.props.offer.starting_version_name})`;
      orderItem = <p><span className="item-info">{startingVersion}</span></p>;
    }
    return (
      <SubscriberDiscountContext.Consumer>
        {(subscriberDiscounts) => (
          <div>
            <div className="text-left">
              {this.renderCounter(this.props.offer.count)} x {this.props.offer.name}
              {orderItem}
            </div>
            <div className="text-right">
              <Price offer={this.props.offer} />
            </div>
            <span className="remove-button"><i className="fa fa-times" aria-hidden="true" onClick={this.removeItem}></i></span>
          </div>
        )}
      </SubscriberDiscountContext.Consumer>
    );
  }
}

export default CartItem;
