import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CrossSVG from './../../../images/cross.svg';
import ReactTooltip from 'react-tooltip';
import FormattedAddress from '../AddressStep/FormattedAddress'
import Dialog from '../../ConfirmationDialog/Dialog.jsx'

class CartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {deleting: false};
  }

  giftClassName = () => {
    if (this.props.item.gift) {
      return 'gift'
    } else {
      return ''
    }
  }

  updateItem = (attr) => {
    return (value) => {
      return (event) => {
        event.preventDefault();
        this.props.updateItem(attr)(value)
      }
    }
  }

  startingVersion = () => {
    return this.props.item.available_versions.filter((version) => version.id == this.props.item.starting_version_id)[0] || this.props.item.immediate_delivery_version
  }

  renderFirstBox = () => {
    return  <React.Fragment>
              Erste Box: {this.startingVersion().description} <br/>
            </React.Fragment>
  }

  renderBoxContainments = () => {
    if (!this.props.item.as_voucher && !this.props.item.donated_box) {
      return  <p>
                { this.props.item.starting_version_id ? this.renderFirstBox() : '' }
                Lieferbar { this.props.item.starting_version_id ? this.startingVersion().delivery_from : this.props.item.delivery_text } mit DHL.
              </p>
    }
  }

  showDialog = () => {
    this.setState({deleting: true});
    event.preventDefault();
  }
  hideDialog = (event) => {
    this.setState({deleting: false});
    event.preventDefault();
  }

  render() {
    return (
      <tr>
        <td className={this.giftClassName()}>
          <span><img src={this.props.item.image_src} alt={this.props.item.offer_title} /></span>
          <div>
            <p>{this.props.item.offer_title}</p>
            { this.props.item.noozie_points ? <p>{this.props.item.noozie_points}</p> : '' }
            { this.renderBoxContainments() }
          </div>
        </td>
        <td>
          <h5 className="mobile">Versand</h5>
          { (this.props.item.gift && this.props.item.as_voucher) || this.props.item.donated_box ? <div>Gutschein</div> : <div>Direkt an:<br/>{this.props.address ? <FormattedAddress address={this.props.address} /> : "" }</div>  }
        </td>
        <td>
          <h5 className="mobile">Preis</h5>
          {this.props.item.price.split("\n").map((price_part, index) => {
            return <React.Fragment key={index}>{price_part}<br/></React.Fragment>
          })}
          { (this.props.address && this.props.address.country === 'AT' && this.props.item.starting_version_id) ? 'Ab August: zzgl. 3€ Versandkosten' : null }
        </td>
        <td className="remove">
          <a href="#" onClick={this.showDialog}>
            <CrossSVG data-tip="Löschen" />
            <ReactTooltip />
            <div className="mobile">Artikel löschen</div>
          </a>
          {this.state.deleting ? <Dialog>
                                    <p className="text-center">Bist du sicher, dass du {this.props.item.offer_title} aus dem Warenkorb entfernen möchtest?</p>
                                    <h5>Damit gehen alle personalisierten Einstellungen für dieses Produkt verloren.</h5>
                                        <a href="#" onClick={this.hideDialog} className="btn btn-primary">ABBRECHEN</a>
                                        <a href="#" onClick={this.props.deleteItem} className="btn btn-success pull-right">JA, ENTFERNEN</a>                                    
                                 </Dialog> : ''}
        </td>
      </tr>
    );
  }
}

CartItem.propTypes = {
  address: PropTypes.object,
  item: PropTypes.object.isRequired,
}

export default CartItem;
