import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckmarkSVG from './../../../images/checkmark.svg';
import {TextField} from './../../shared/StandardInputs'

class CartItem extends Component {
  constructor(props) {
    super(props);
  }

  update = (attr) => {
    return (event) => {
      this.props.update(attr)(event.target.value)
    }
  }

  defaultMessage = () => {
    return "Ich möchte dir mit einer Box von brandnooz eine Freude bereiten!\n\n" +
            "Sobald sie bei dir eintrifft, kannst du dich auf tolle Produkte in einer Box prall gefüllt mit süßen und " +
            "herzhaften Leckereien freuen.\n\n" +
            "Viele Grüße,\n" + (this.props.invoiceAddress && this.props.invoiceAddress.line1)
  }

  render() {
    return (
      <div className="gift-item">
        <div className="info">
          <div className="gift">
            <span><img src={this.props.item.image_src} alt={this.props.item.offer_title} /></span>
          </div>
          <strong>Direktversand</strong> {this.props.item.offer_title}
        </div>
        {
          this.props.noMessage ? "" :
            <React.Fragment>
              <div className="row">
                <div className="col col-md-6">
                  <TextField name="E-Mail-Adresse des Beschenkten (Pflichtfeld)"
                             handleChange={this.update('personal_message_email')}
                             value={this.props.item.personal_message_email || ""}
                             errors={this.props.item.errors && this.props.item.errors['personal_message_email']} />
                  <small>Diese E-Mail-Adresse löschen wir selbstverständlich, sobald wir die Nachricht versendet haben.</small>
                </div>
              </div>
              <div className="row">
                <div className="col col-md-6">
                  <label htmlFor={`personal_message_${this.props.item.id}`}>
                    Persönliche Botschaft
                  </label>
                  <textarea id={`personal_message_${this.props.item.id}`} rows="12" onChange={this.update('personal_message')} value={this.props.item.personal_message || this.defaultMessage()} />
                  { this.props.item.errors && this.props.item.errors['personal_message'] ? <div className="errors">{this.props.item.errors['personal_message']}</div> : '' }
                </div>
              </div>
            </React.Fragment>
        }
        <div className="row">
          <div className="col col-md-6 radio">
            <label>
              <input type="checkbox" checked={this.props.noMessage} onChange={this.props.toggleMessage}/>
              <span><CheckmarkSVG /></span>
              Keine persönliche Nachricht gewünscht.
            </label>
          </div>
        </div>
      </div>
    );
  }
}

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  noMessage: PropTypes.bool,
  toggleMessage: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
}

export default CartItem;
