import React, { Component } from 'react';
import CookieImage from '../images/CookieImage.svg';

class CookieExplanation extends Component {
  render() {
    return(
      <div className="centered">
        <h4>Cookies</h4>
        <p>
          Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, unsere Website und unsere Produkte zu verbessern.
        </p>
        <p>
          Weitere Details findest du in unserer <a href="/datenschutzerklaerung#cookies" target="_blank">Datenschutzrichtlinie</a>.
        </p>
        <a href="#" className="btn btn-highlight" onClick={this.props.allowAllCookies}>
          <CookieImage />
          <span />
          Ich akzeptiere
        </a>
        <a href="#" className="btn btn-primary" onClick={this.props.configure}>
          Cookies verwalten
        </a>
      </div>
    )
  }
}

export default CookieExplanation;
