import React, { Component } from 'react'
import ReactDOM from 'react-dom'

export default class DynamicGiftCheckoutButton extends Component {
  constructor(props) {
    super(props)
  }

  handleClick = (e) => {
    this.props.putIntoShoppingCart(this.props.offer)
  }

  renderAddToCartButton() {
    return (
      <a onClick={this.handleClick} className={"btn ".concat(this.props.className)} rel="nofollow">
        <i className="fas fa-gift"></i>
        &nbsp;
        Verschenken
      </a>
    );
  }

  render() {
    return ReactDOM.createPortal(this.renderAddToCartButton(), this.props.element)
  }
}
