import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckmarkSVG from './../../../images/checkmark.svg';
import { TextField, Select } from './../../shared/StandardInputs'

class AddressForm extends Component {
  constructor(props) {
    super(props);
    this.countries = [{ label: 'Deutschland', value: 'DE' }, { label: 'Österreich', value: 'AT' }]
    this.state = {
      address: {
        id: this.props.address.id,
        first_name: this.props.address.first_name,
        last_name: this.props.address.last_name,
        addition: this.props.address.addition,
        street: this.props.address.street,
        street_number: this.props.address.street_number,
        zip_code: this.props.address.zip_code,
        city: this.props.address.city,
        country: this.props.address.country,
        is_parcel_station: this.props.address.is_parcel_station
      },
      selectedCountry: this.countries.filter((country) => country.value === this.props.address.country)[0],
      errors: {},
      persistent: false
    }
  }

  update = (attribute) => {
    return (event) => {
      const newAddress = { ...this.state.address, ...{ [attribute]: event.target.value } }
      this.setState({ address: newAddress })
    }
  }

  updateCountry = (selected) => {
    const newAddress = { ...this.state.address, ...{ country: selected.value } }
    this.setState({ address: newAddress, selectedCountry: selected })
  }

  updateParcelStation = (value) => {
    return (event) => {
      const newAddress = { ...this.state.address, ...{ is_parcel_station: value, street: (value ? "" : "Packstation"), street_number: "", addition: "" } }
      this.setState({ address: newAddress })
    }
  }

  save = (event) => {
    this.props.save(event, this.state.address, this.state.persistent)
  }

  togglePersistence = (event) => {
    if (this.state.persistent) {
      this.setState({ persistent: false })
    } else {
      this.setState({ persistent: true })
    }
  }

  renderParcelStation = () => {
    return <React.Fragment>
      <div className="line5">
        <TextField value={this.state.address.street_number} placeholder="Packstation Nr." handleChange={this.update('street_number')} errors={this.errorsFor('street_number')} />
        <TextField value={this.state.address.addition} placeholder="Postnummer" handleChange={this.update('addition')} errors={this.errorsFor('addition')} />
      </div>
    </React.Fragment>
  }

  renderStandardAddress = () => {
    return <React.Fragment>
      <div className="line2">
        <TextField value={this.state.address.addition} placeholder="Adresszusatz" handleChange={this.update('addition')} errors={this.errorsFor('addition')} />
      </div>
      <div className="line3">
        <TextField value={this.state.address.street} placeholder="Straße" handleChange={this.update('street')} errors={this.errorsFor('street')} />
        <TextField value={this.state.address.street_number} placeholder="Hausnr." handleChange={this.update('street_number')} errors={this.errorsFor('street_number')} />
      </div>
    </React.Fragment>
  }

  renderParcelStationSelector = () => {
    if (this.state.address.is_parcel_station) {
      return <div className="address-type">
        <label onClick={this.updateParcelStation(false)}><i className="fas fa-home" />Adresse</label>
        <label className="selected"><i className="fab fa-dhl" />Packstation</label>
      </div>
    } else {
      return <div className="address-type">
        <label className="selected"><i className="fas fa-home" />Adresse</label>
        <label onClick={this.updateParcelStation(true)}><i className="fab fa-dhl" />Packstation</label>
      </div>
    }
  }

  errorsFor = (attribute) => this.props.address.errors && this.props.address.errors[attribute]

  render() {
    return (
      <React.Fragment>
        <div className="top">
          <i className="fas fa-times" onClick={this.props.reset} />
        </div>
        {
          this.props.address.can_change ? this.renderParcelStationSelector() : ""
        }
        {
          this.errorsFor('base') ? <div className="alert alert-danger">{this.errorsFor('base')}</div> :
            <div className="alert alert-warning">Bitte beachte, dass wir nur nach Deutschland und Österreich versenden können.</div>
        }
        {
          (this.props.address.can_change && this.props.address.country == 'AT') ? <div>Für den Versand nach Österreich fallen leider erhöhte Versandkosten an.</div> : ""
        }
        <div className="line1">
          <TextField value={this.state.address.first_name} placeholder="Vorname" handleChange={this.update('first_name')} errors={this.errorsFor('first_name')} />
          <TextField value={this.state.address.last_name} placeholder="Nachname" handleChange={this.update('last_name')} errors={this.errorsFor('last_name')} />
        </div>
        {this.state.address.is_parcel_station ? this.renderParcelStation() : this.renderStandardAddress()}
        <div className="line4">
          <TextField value={this.state.address.zip_code} placeholder="PLZ" handleChange={this.update('zip_code')} errors={this.errorsFor('zip_code')} />
          <TextField value={this.state.address.city} placeholder="Stadt" handleChange={this.update('city')} errors={this.errorsFor('city')} />
        </div>
        <div>
          <Select placeholder="Land" column="country" onChange={this.updateCountry} options={this.countries} value={this.state.selectedCountry} />
        </div>
        {
          (!this.props.guest && (this.props.address.id == null || !this.props.address.persisted)) ? <div>
            <label>
              <input type="checkbox" checked={this.state.persistent} onChange={this.togglePersistence} />
              <span><CheckmarkSVG /></span>
              Adresse dauerhaft speichern?
            </label>
            <small>Ohne diese Option wird die Adresse 30 Tage nach Versand aus unserem System gelöscht.</small>
          </div> : ""
        }
        <div>
          <a className="btn btn-green btn-xs" onClick={this.save} >Speichern</a>
        </div>
      </React.Fragment>
    );
  }
}

AddressForm.propTypes = {
  address: PropTypes.object.isRequired,
  guest: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired
}

export default AddressForm;
