import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PaymentProfile extends Component {
  constructor(props) {
    super(props);
    this.state= {
      delete: false
    }
  }

  paymentProfileClass = () => {
    if (this.props.selected) {
      return "card chosen"
    } else {
      return "card"
    }
  }

  editPaymentProfile = (event) => {
    event.stopPropagation()
    this.setState({editMode: true})
  }

  reset = () => {
    this.setState({editMode: false})
    this.props.reset()
  }

  save = (event, address, persistent) => {
    this.props.save(event, address, persistent, () => this.setState({editMode: false}))
  }

  choose = (event) => {
    if (!this.props.selected) {
      this.props.choose(event)
    }
  }

  delete = (event) => {
    this.setState({delete: true})
  }

  render() {
    return (
      <div className="col-md-4">
        <div className={this.paymentProfileClass()} onClick={this.choose}>
          {
            <React.Fragment>
              {
                this.props.paymentProfile.deletable ?
                  <div className="top">
                    { this.state.delete ? <a href="#" className="btn btn-danger" onClick={this.props.delete}>Wirklich löschen!</a> : <i className="fas fa-trash" data-tip="Löschen" onClick={this.delete} /> }
                  </div> : ""
              }
              { this.props.paymentProfile.description }
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

PaymentProfile.propTypes = {
  paymentProfile: PropTypes.object.isRequired,
  choose: PropTypes.func,
  delete: PropTypes.func,
  selected: PropTypes.bool
}

export default PaymentProfile;
