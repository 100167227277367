import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CartItem from './CartItem';
import ShoppingCartSum from './ShoppingCartSum';
import VoucherLink from './VoucherLink';
import { slide as Menu } from 'react-burger-menu';
import ShoppingCartIcon from './ShoppingCartIcon';
import { checkoutCartTracking } from '../../utilities/google_analytics_helpers';

class ShoppingCartSidebar extends Component {
  constructor(props) {
    super(props)
    if (this.props.open) {
      $('body').addClass('cart-open')
    }
  }
  closeCartSidebar = () => {
    checkoutCartTracking(this.props.offers)
    localStorage.setItem('cart_open', false)
  }

  updateCartItem = (offer) => {
    return (value) => {
      this.props.updateItem(offer, value)
    }
  }

  onStateChange = (state) => {
    if (state.isOpen != this.props.open) [
      this.props.toggleBody()
    ]
  }

  render() {
    return ReactDOM.createPortal(
      <Menu customBurgerIcon={ <ShoppingCartIcon cart={ this.props.offers } /> }
            onStateChange={this.onStateChange} isOpen={this.props.open} right>
        <div className="row">
          <h5 className=" upcase shopping-sidebar-title">Warenkorb</h5>
          {this.props.offers.map((offer) =>
            <div key={offer.id} className="cart-item-wrap col-xs-12">
              <CartItem
                onOfferRemove={this.props.onOfferRemove}
                updateItem={this.updateCartItem(offer)}
                offer={offer}
              />
            </div>
          )}
          <div className="sum-wrap col-xs-12">
            <h4>Summe: </h4>
            <ShoppingCartSum
              cart={this.props.offers}
            />
            <VoucherLink />
          </div>
          <div className="col-xs-12 text-center">
            <a className="btn btn-success order-now" href="/bestellung" onClick={this.closeCartSidebar}>Jetzt bestellen</a>
            <br />
          </div>
        </div>
      </Menu>,
      document.getElementById('shopping-cart-sidebar')
    );
  }
}

export default ShoppingCartSidebar;
