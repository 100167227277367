import React from 'react';

export default function VoucherLink(props) {
  return (
    <div className="text-left">
      <span className="item-info">
        Ich habe einen <a href="/vouchers/search" data-toggle="modal" data-target="#modal-ajax" className="validate-coupon-link"><strong>Gutscheincode</strong></a>
      </span>
    </div>
  )
}
