import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from './../../shared/StandardInputs'

class ShippingCosts extends Component {
  constructor(props) {
    super(props);
  }

  shippingCosts = () => {
    if (this.props.cart.shipping_costs) {
      return (
        <React.Fragment>
          {this.props.cart.shipping_costs.map((cost, index) => <p key={`shippingCostLabel${index}`}>{cost}</p>)}
        </React.Fragment>
      )
    } else {
      return "inklusive"
    }
  }

  selectedSubscription = () => this.props.cart.subscription_choices.filter((subscription) => subscription.value === this.props.cart.ship_within_subscription_id)[0]

  shippingCostsExplanations = () => {
    if (this.props.cart.product_box_shipping_price_cents > 0) {
      return (
        <React.Fragment>
          <p>*Es gibt Boxen, die mit Einzelprodukten zusammen versendet werden können. Wenn du dir zum Beispiel ein Classic Box Abo in den Warenkorb legst, kannst du Versandkosten sparen.</p>
          {this.props.cart.subscription_choices ? <div>
            <p>
              Als Abonennt kannst du auch Einzelprodukte versandkostenfrei zu deiner nächsten Box hinzufügen:
            </p>
            <Select placeholder="Versende Einzelprodukte mit nächster Abobox" onChange={this.props.updateShipWithinSubscription} options={this.props.cart.subscription_choices} value={this.selectedSubscription()} isClearable={true} />
          </div> : null}
        </React.Fragment>
      )
    } else if (this.props.cart.ship_within_subscription_id) {
      return (
        <div>
          <p>
            Du hast ausgewählt, die enthaltenen Einzelprodukte versandkostenfrei zu deiner nächsten Box hinzufügen:
          </p>
          <Select placeholder="Versende Einzelprodukte mit nächster Abobox" onChange={this.props.updateShipWithinSubscription} options={this.props.cart.subscription_choices} value={this.selectedSubscription()} isClearable={true} />
        </div>
      )
    }
  }

  render() {
    return (
      <tr>
        {this.props.updateShipWithinSubscription ?
          <td className="no-border foot">
            {this.shippingCostsExplanations()}
            <p>* Näheres zu Versandkosten siehe auch unsere <a href="/faq#versandkosten" target="_blank">FAQ</a></p>
          </td> : null}
        <td className="no-border no-mobile" />
        <td className="no-border" colSpan={this.props.updateShipWithinSubscription ? "2" : "1"}>
          Versandkosten*
        </td>
        <td className="no-border">
          {this.shippingCosts()}
        </td>
        <td className="no-mobile" />
      </tr>
    );
  }
}

ShippingCosts.propTypes = {
  cart: PropTypes.object.isRequired,
  updateShipWithinSubscription: PropTypes.func
}

export default ShippingCosts;
