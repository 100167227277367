import React, { Component } from 'react';

class Dialog extends Component {
  render() {
    return <div className="dialog-background">
             <div>
               { this.props.children }
             </div>
           </div>
  }
}

export default Dialog;
