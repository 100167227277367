import React, { Component } from 'react';
import SidebarLayout from './SidebarLayout';
import SubscriberDiscountContext from './SubscriberDiscountContext';
import { addToCartTracking, reportVirtualPageRequest } from '../../utilities/google_analytics_helpers';

class Shop extends Component {
  constructor(props) {
    super(props);
    const url = location.href;
    const hash = url.substring(url.indexOf("#") + 1);
    if (hash == 'cart') {
      localStorage.setItem('cart_open', 'true')
    }
    const open = (localStorage.getItem('cart_open') == 'true') && location.pathname != '/bestellung'
    if (location.pathname == '/bestellung') {
      $('#shopping-cart-sidebar').hide()
    }
    this.state = { cart: [], openSidebar: open };
  }

  componentDidMount() {
    $.getJSON('/order_items').done((data) => {
      this.setState((prevState, props) => {
        let newState = prevState;
        newState.cart = data.offers.sort((a, b) => { return a.is_subscription ? 0 : 1 });
        return newState;
      });
    });
  }

  updateCartItem = (offer, updater) => {
    let updatedCart = [...this.state.cart]
    const index = updatedCart.indexOf(offer)
    updatedCart[index] = { ...updatedCart[index], ...updater }
    this.setState({ cart: updatedCart })
    if (updater.count > 0) {
      $.ajax({
        url: `/order_items/${offer.id}/change`,
        data: { order_item: updater },
        type: "PATCH"
      });
    }
  }

  toggleBody = () => {
    if (location.pathname != '/bestellung') {
      open = !this.state.openSidebar
      if (open) {
        reportVirtualPageRequest(1)()
      }
      this.setState({ openSidebar: open }, () => {
        localStorage.setItem('cart_open', open)
        $('body').toggleClass('cart-open')
      })
    }
  }


  subscriptionCount = () => {
    return this.state.cart.filter((offer) => {
      return offer.is_subscription;
    }).reduce((acc, offer) => {
      return acc + offer.count;
    }, 0);
  }

  subscriberDiscounts = () => {
    return {
      singleBoxes: this.props.is_subscriber || this.subscriptionCount() >= 1,
      allBoxes: this.props.is_subscriber || this.subscriptionCount() > 1
    };
  }

  putIntoShoppingCart = (offer) => {
    return $.ajax({
      type: "POST",
      url: `/order_items/${offer.id}`,
      success: () => {
        if (this.offerIsInCart(offer)) {
          if (!offer.is_subscription) {
            this.incrementOfferCount(offer);
          }
        } else {
          this.addOfferToCart(offer);
        }
        if (!this.state.openSidebar) {
          this.toggleBody()
        }
        addToCartTracking(offer)
      },
      error: (xhr) => {
        toastr.error(xhr.responseText);
      }
    });
  }

  checkoutNow = (offer) => {
    if (this.offerIsInCart(offer)) {
      window.location.href = "/bestellung";
    } else {
      this.putIntoShoppingCart(offer).done(() => {
        window.location.href = "/bestellung";
      });
    }
  }

  offerIsInCart = (offer) => {
    const offerFound = this.state.cart.find((element) => {
      return element.id === offer.id;
    });
    return offerFound !== undefined;
  }

  addOfferToCart = (offer) => {
    this.setState((prevState, props) => {
      let newState = prevState;
      offer.count = 1;
      newState.cart.push(offer);
      return newState;
    });
  }

  incrementOfferCount = (offer) => {
    this.setState((prevState, props) => {
      const index = prevState.cart.findIndex((element) => {
        return element.id === offer.id;
      });
      let newState = { ...prevState };
      newState.cart[index].count += 1;
      return newState;
    });
  }

  shoppingCartItemCount = () => {
    return this.state.cart.reduce((sum, offer) => {
      return sum + offer.count;
    }, 0)
  }

  removeOfferFromCart = (offer) => {
    this.setState((prevState, props) => {
      const index = prevState.cart.findIndex((element) => {
        return element.id === offer.id;
      })
      let newState = prevState;
      newState.cart.splice(index, 1)
      return newState;
    })
    $.ajax({
      url: `/order_items/${offer.id}/remove`,
      type: "DELETE"
    });
  }

  render() {
    return (
      <SubscriberDiscountContext.Provider value={this.subscriberDiscounts()}>
        <SidebarLayout
          itemCount={this.shoppingCartItemCount()}
          removeOfferFromCart={this.removeOfferFromCart}
          cart={this.state.cart}
          updateItem={this.updateCartItem}
          putIntoShoppingCart={this.putIntoShoppingCart}
          checkoutNow={this.checkoutNow}
          offerIsInCart={this.offerIsInCart}
          openSidebar={this.state.openSidebar}
          toggleBody={this.toggleBody}
        />
      </SubscriberDiscountContext.Provider>
    );
  }
}

export default Shop;
