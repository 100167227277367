import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddressForm from './AddressForm';
import FormattedAddress from './FormattedAddress';
import Dialog from '../../ConfirmationDialog/Dialog.jsx';

class Address extends Component {
  constructor(props) {
    super(props);
    this.state= {
      editMode: this.props.editMode || false,
      deleting: false
    }
  }

  addressClass = () => {
    if (this.state.editMode) {
      return "card edit"
    }
    if (this.props.selected) {
      return "card chosen"
    } else {
      return "card"
    }
  }

  editAddress = (event) => {
    event.stopPropagation()
    this.props.choose(event)
    this.setState({editMode: true, delete: false})
  }

  reset = () => {
    this.setState({editMode: false})
    this.props.reset()
  }

  save = (event, address, persistent) => {
    this.setState({delete: false})
    this.props.save(event, address, persistent, () => this.setState({editMode: false}))
  }

  choose = (event) => {
    if (!this.props.selected) {
      this.props.choose(event)
    }
  }

  showDialog = () => {
    this.setState({deleting: true});
    event.preventDefault();
  }
  hideDialog = (event) => {
    this.setState({deleting: false});
    event.preventDefault();
  }

  render() {
    return (
      <div className="col-md-4">
        <div className={this.addressClass()} onClick={this.choose}>
          {
            this.state.editMode ?
              <AddressForm address={this.props.address} guest={this.props.guest} save={this.save} reset={this.reset} selected={this.props.selected}/> :
              <React.Fragment>
                <div className="top">
                  {
                    this.props.editable ?
                      <React.Fragment>
                        <i className="fas fa-edit" onClick={this.editAddress} />
                        <i className="fas fa-trash" data-tip="Löschen" onClick={this.showDialog} />
                        {this.state.deleting ?
                          <Dialog>
                            <p className="text-center">Bist du sicher, dass du diese Adresse aus deinem Adressbuch entfernen möchtest?</p>
                            <a href="#" onClick={this.hideDialog} className="btn btn-primary">ABBRECHEN</a>
                            <a href="#" onClick={this.props.delete} className="btn btn-success pull-right">JA, ENTFERNEN</a>
                         </Dialog> : ''
                        }
                      </React.Fragment> : ''
                  }
                </div>
                <FormattedAddress address={this.props.address} />
              </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

Address.propTypes = {
  address: PropTypes.object.isRequired,
  guest: PropTypes.bool.isRequired,
  choose: PropTypes.func,
  edit: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  delete: PropTypes.func,
  save: PropTypes.func.isRequired,
  selected: PropTypes.bool
}

export default Address;
