import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class CheckoutNowButton extends Component {
  constructor(props) {
    super(props);
    while (this.props.element.firstChild) {
      this.props.element.removeChild(this.props.element.firstChild);
    }
  }

  checkoutNow = () => {
    this.props.checkoutNow(this.props.offer);
  }

  render() {
    let button = <a className="btn btn-default" onClick={this.checkoutNow}> Jetzt bestellen! </a>;
    return ReactDOM.createPortal(button, this.props.element);
  }
}
