import React, { Component } from 'react';
import GameEventDialog from './GameEventDialog';
import { jwtDecode } from 'jwt-decode';

class GameEvent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...jwtDecode(props.token), ...{
        showDialog: false,
        bubbleShow: true,
        bubbleText: props.bubbleText,
        hiddenThisCycle: props.hiddenThisCycle
      }
    }
  }

  closeDialog = (event) => {
    event.preventDefault()
    this.setState({ showDialog: false })
  }

  showDialog = () => {
    this.setState({ showDialog: true })
  }

  closeBubble = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ bubbleShow: false })
  }

  remove = (hiddenElement) => {
    let hiddenElements = [...this.state.hiddenElements]
    const index = this.state.hiddenElements.findIndex((element) => element.id == hiddenElement.id)
    hiddenElements.splice(index, 1)
    this.setState({ bubbleShow: false })
    $('#game-explanation .big').animate({ fontSize: 0 }, 200)
    this.setState({ bubbleShow: true, bubbleText: this.bubbleTextFor(hiddenElement.prize), hiddenElements: hiddenElements, hiddenThisCycle: (this.state.hiddenThisCycle - 1) }, () => {
      $('#game-explanation .big').animate({ fontSize: '64px' }, 500)
    })
    $(`#${hiddenElement.id}`).remove()
  }

  bubbleTextFor = (prize) => {
    if (prize) {
      return "Ui! Ein besonderes Ei! Schau es dir mal in deinem Profil an."
    } else {
      return "Super! Du hast ein Ei gefunden! Es ist in deinem Profil gespeichert."
    }
  }

  found = (hiddenElement) => {
    return (event) => {
      event.preventDefault()
      $.post(`/events/${hiddenElement.id}/found`).done(() => {
        this.remove(hiddenElement)
      })
    }
  }

  hide = (hiddenElement) => {
    return () => {
      const image_number = hiddenElement.image % $('img:visible').length
      let image = $('img:visible').not('.slick-cloned img')[image_number]
      if ($(image).closest('#game-event').length > 0) {
        image = $('img:visible')[image_number - 1]
      }
      let wrapper = $(`<span class=${hiddenElement.id} style="position:relative;display:inline-block;"></span>`)
      let hiddenElementTag = `<img src="${hiddenElement.imageUrl}" id="${hiddenElement.id}"/>`
      $(image).wrap(wrapper)
      $('.' + hiddenElement.id).append(hiddenElementTag)
      const imageWidth = parseInt($(image).css('width'))
      const maxWidth = imageWidth > 0 ? Math.max(imageWidth / 4, 12) : 40
      const width = `${Math.min(parseInt(Math.random() * 11) + 30, maxWidth)}px`
      $(`img#${hiddenElement.id}`).css({ ...hiddenElement.position, ...{ width: width, maxWidth: width, position: 'absolute' } })
        .click(this.found(hiddenElement))
    }
  }

  componentDidMount = () => {
    if (this.state.hiddenThisCycle > 0) {
      this.state.hiddenElements.forEach((hiddenElement) => {
        if ((hiddenElement.path.endsWith('.+') && location.pathname.match(hiddenElement.path)) || (location.pathname == hiddenElement.path)) {
          window.setTimeout(this.hide(hiddenElement), 500)
        }
      })
    }
    window.showGameDialog = this.showDialog
  }

  redirectToProfile = (event) => {
    if (this.props.profilePath) {
      window.location.href = this.props.profilePath
    }
  }

  clickableStyle = () => {
    if (this.props.profilePath) {
      return { cursor: 'pointer' }
    } else {
      return {}
    }
  }

  clickableTitle = () => {
    if (this.props.profilePath) {
      return 'Zum Profil'
    }
  }

  render() {
    return (
      <div id="game-event">
        {
          this.state.bubbleShow ? (
            <div className="easter-egg-bubble" onClick={this.redirectToProfile} title={this.clickableTitle()} style={this.clickableStyle()}>
              <div className="centered">{this.state.bubbleText}</div>
              <img className="close" src="/game_event/closed.webp" onClick={this.closeBubble} />
            </div>
          ) : null
        }
        <div id="game-explanation" onClick={this.showDialog}>
          {
            this.props.signedIn ?
              <div className="centered explanation">
                {
                  this.state.hiddenThisCycle > 0 ? <React.Fragment>
                    <span className="big">{this.state.hiddenThisCycle}</span>
                    <p>{this.state.hiddenThisCycle == 1 ? 'Ei ist' : 'Eier sind'} heute noch versteckt</p>
                  </React.Fragment> : <React.Fragment>
                    <p className="down">Du hast heute alle Eier gefunden.</p>
                    <p>Komm morgen wieder!</p>
                  </React.Fragment>
                }
              </div> :
              <div className="centered bottom explanation">{this.props.loggedOffPlaceholder}</div>
          }
          <img src="/game_event/easter-egg.webp" onClick={this.showDialog} />
          <div className="egg-shadow"></div>
        </div>
        {this.state.showDialog ? <GameEventDialog closeDialog={this.closeDialog} signedIn={this.props.signedIn} profilePath={this.props.profilePath} /> : null}
      </div>
    );
  }
}

export default GameEvent;
