import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CartItem from './ConfirmationStep/CartItem';
import ShippingCosts from './CartOverview/ShippingCosts';
import ReactTooltip from 'react-tooltip';
import { removeCartTracking } from '../../utilities/google_analytics_helpers';

class ConfirmationStep extends Component {
  constructor(props) {
    super(props);
    this.state = { submitted: false }
  }

  componentDidMount = () => {
    this.props.reportVirtualPageRequest()
  }

  requirementsFullfilled = () => {
    return this.props.cart.items.length > 0
  }

  preventDefault = (event) => {
    event.preventDefault()
  }

  requirementsFullfilled = () => {
    return this.props.cart.items.length > 0
  }

  removeItem = (cartItem) => {
    return (event) => {
      event.preventDefault()
      removeCartTracking(cartItem);
      $.ajax({
        url: `/order_items/${cartItem.id}`,
        type: "DELETE"
      }).done((data) => {
        this.props.updateCart(data.cart)
      })
    }
  }

  submit = (event) => {
    event.preventDefault()
    this.props.pushDataLayer()
    this.setState({ submitted: true }, () => {
      this.props.submitCart(event)
    })
  }

  render() {
    return (
      <div>
        <h4 className="align-left">Bestellung abschließen</h4>
        <div className="row">
          <div className="col col-md-9">
            <table className="table responsive">
              <thead>
                <tr>
                  <th>Artikel</th>
                  <th>Versand</th>
                  <th>Preis</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.props.cart.items.map((item) => <CartItem key={`cartItem${item.id}`}
                  address={this.props.deliveryAddress}
                  item={item}
                  deleteItem={this.removeItem(item)} />)}
              </tbody>
              <tfoot>
                <ShippingCosts cart={this.props.cart} />
                <tr>
                  <td className="no-border foot" rowSpan="2" colspan="4">
                    <div>
                      <small>
                        Mit deiner Bestellung erklärst du dich mit unseren <a href="/nutzungsbedingungen-agb" target="_blank">Allgemeinen Geschäftsbedingungen</a> einverstanden, die du <a href="/nutzungsbedingungen-agb" target="_blank">hier</a> lesen und ausdrucken kannst.
                      </small>
                    </div>
                  </td>
                  <td className="no-mobile" />
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="col col-md-1"></div>
          {this.props.paymentProfile ? <div className="col col-md-2">
            <h5 className="align-left">Bezahlung</h5>
            <p>
              {this.props.paymentProfile.integration}<br />
              {this.props.paymentProfile.description}
            </p>
          </div> : ""}
        </div>
        <div className="bottom center">
          {
            this.state.submitted ?
              <React.Fragment>
                <a href='#' onClick={this.preventDefault} className="btn btn-invalid" data-tip="Ihre Bestellung wird bearbeitet... wir bitten um einen kurzen Augenblick Geduld...">
                  <span className="loading-spinner" />
                  Jetzt für {this.props.cart.price} kostenpflichtig bestellen
                </a>
                <ReactTooltip />
              </React.Fragment> :
              this.requirementsFullfilled() ?
                <a href='#' onClick={this.submit} className="btn btn-green" >Jetzt für {this.props.cart.price} kostenpflichtig bestellen</a> :
                <React.Fragment>
                  <a href='#' onClick={this.props.toastr('Dein Warenkorb ist leider wieder leer')} className="btn btn-invalid" data-tip="Dein Warenkorb ist leider wieder leer" >Jetzt für {this.props.cart.price} kostenpflichtig bestellen</a>
                  <ReactTooltip />
                </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

ConfirmationStep.propTypes = {
  cart: PropTypes.object.isRequired,
  deliveryAddress: PropTypes.object,
  invoiceAddress: PropTypes.object,
  paymentProfile: PropTypes.object,
  submitCart: PropTypes.func.isRequired
}

export default ConfirmationStep;
