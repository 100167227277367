import React, { Component } from 'react';
import FormattedMoney from './FormattedMoney';
import totalPrice from '../../utilities/shop_calculations';
import SubscriberDiscountContext from './SubscriberDiscountContext';

class ShoppingCartSum extends Component {
  render() {
    return (
      <SubscriberDiscountContext.Consumer>
        { (subscriberDiscounts) => (
          <div className="sum-prices-wrap sum-prices-seperated">
            <div>
              <span className="item-info">Gesamt </span>
              <FormattedMoney priceCents={totalPrice(this.props.cart, subscriberDiscounts)} />
            </div>
          </div>
        )}
      </SubscriberDiscountContext.Consumer>
    );
  }
}

export default ShoppingCartSum;
