import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CartItem from './GiftStep/CartItem';
import DownloadSVG from './../../images/download.svg';
import ReactTooltip from 'react-tooltip';

class CartOverview extends Component {
  constructor(props) {
    super(props);
    let noMessage = {};
    this.props.cart.items.forEach((item) => noMessage[item.id] = false)
    this.state = { noMessage: noMessage }
  }

  requirementsFullfilled = () => {
    return this.allNonVoucherGiftsEdited()
  }

  allNonVoucherGiftsEdited = () => this.props.cart.items.reduce(((memory, item) => memory && (!item.gift || item.as_voucher || !!item.voucher_code || (!!item.personal_message_email && !!item.personal_message || this.state.noMessage[item.id]))), true)

  allVoucherItems = () => this.props.cart.items.reduce(((memory, item) => memory && (!item.gift || item.as_voucher || !!item.voucher_code)), true)

  duplicateItem = (id) => {
    return (event) => {
      event.preventDefault()
      $.post(`/order_items/${id}/duplicate`).done((data) => {
        this.props.updateCart(data.cart)
      })
    }
  }

  removeItem = (id) => {
    return (event) => {
      event.preventDefault()
      $.ajax({
        url: `/order_items/${id}`,
        type: "DELETE"
      }).done((data) => {
        this.props.updateCart(data.cart)
      })
    }
  }

  toggleMessage = (id) => {
    return (event) => {
      const noMessage = {...this.state.noMessage, ...{[id]: !this.state.noMessage[id]}}
      if (noMessage[id]) {
        this.props.updateItem(id)('personal_message_email')(null)
        this.props.updateItem(id)('personal_message')(null)
      }
      this.setState({noMessage: noMessage})
    }
  }

  renderPersonalMessages = () => {
    return  <React.Fragment>
              <h4 className="align-left">Persönliche Nachricht für direkt versendete Geschenkboxen</h4>
              <p>
                Du kannst dem Beschenkten eine persönliche Grußbotschaft zukommen lassen, die am Versandtag der ersten Box
                versendet wird.
              </p>
              <p>
                Somit weiß der Empfänger, dass eine Sendung unterwegs ist und bekommt auch eine Sendungsnummer für die
                Sendungsverfolgung.
              </p>
              <h5 className="align-left">Artikel</h5>
              {
                this.props.cart.items.map((item) => {
                  if (item.gift && !item.as_voucher && !item.voucher_code) {
                    return <CartItem key={`personalMessage-${item.id}`}
                                     invoiceAddress={this.props.invoiceAddress}
                                     item={item}
                                     noMessage={this.state.noMessage[item.id]}
                                     toggleMessage={this.toggleMessage(item.id)}
                                     update={this.props.updateItem(item.id)} />
                  }
                })
              }
            </React.Fragment>
  }

  render() {
    return (
      <div id="individualize-gifts">
        <h4 className="align-left">Geschenkkarte für Gutscheine</h4>
        <div className="row">
          <div className="col col-md-3">
            <a href="/downloads/Geschenkkarte_Freude-schenken.pdf">
              <img src='/downloads/Geschenkkarte_Freude-schenken.png' />
              <h5>Freude schenken</h5>
              <span><DownloadSVG /> Herunterladen</span>
            </a>
          </div>
          <div className="col col-md-3">
            <a href="/downloads/Geschenkkarte_Weihnachten.pdf">
              <img src='/downloads/Geschenkkarte_Weihnachten.png' />
              <h5>Frohe Weihnachten!</h5>
              <span><DownloadSVG /> Herunterladen</span>
            </a>
          </div>
          <div className="col col-md-3">
            <a href="/downloads/Geschenkkarte_Geburtstag.pdf">
              <img src='/downloads/Geschenkkarte_Geburtstag.png' />
              <h5>Alles Gute zum Geburtstag!</h5>
              <span><DownloadSVG /> Herunterladen</span>
            </a>
          </div>
          <div className="col col-md-3">
            <a href="/downloads/Geschenkkarte_Danke.pdf">
              <img src='/downloads/Geschenkkarte_Danke.png' />
              <h5>Danke</h5>
              <span><DownloadSVG /> Herunterladen</span>
            </a>
          </div>
        </div>
        { this.allVoucherItems() ? '' : this.renderPersonalMessages() }
        <div className="bottom center">
          {
            this.requirementsFullfilled() ?
              <a href='#' onClick={this.props.setStep(5)} className="btn btn-green" >Weiter</a> :
              <React.Fragment>
                <a href='#' onClick={this.props.toastr('Bitte erst persönliche Nachrichten bearbeiten')} className="btn btn-invalid" data-tip="Bitte erst persönliche Nachrichten bearbeiten" >Weiter</a>
                <ReactTooltip />
              </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

CartOverview.propTypes = {
  cart: PropTypes.object.isRequired,
  invoiceAddress: PropTypes.object,
  saveCart: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired
}

export default CartOverview;
