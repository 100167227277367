import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlagSVG from './../../images/zielflagge.svg';

class Stepper extends Component {
  constructor(props) {
    super(props);
  }

  setStep = (step) => {
    if (step > this.state.maxStep) {
      this.setState({step: step, maxStep: step})
    } else {
      this.setState({step: step})
    }
  }

  steps = () => {
    if (this.props.giftIncluded) {
      return ['Warenkorbübersicht', 'Adresse angeben', 'Zahlungsart wählen', 'Geschenke individualisieren', 'Bestellung abschließen']
    } else if (!this.props.paymentRequired) {
      return ['Warenkorbübersicht', 'Adresse angeben', 'Bestellung abschließen']
    } else {
      return ['Warenkorbübersicht', 'Adresse angeben', 'Zahlungsart wählen', 'Bestellung abschließen']
    }
  }

  render() {
    return (
      <div id="order-step">
        {this.steps().map((step, index) => {
          if (index + 1 > this.props.maxStep) {
            return <span key={`step_${index}`} className="step">{step}</span>
          } else if (index + 1 == this.props.step) {
            return <span key={`step_${index}`} className="step active">{step}</span>
          } else {
            return <a key={`step_${index}`} className="step" href='#' onClick={this.props.setStep(index + 1)}>{step}</a>
          }
        })}
        <span className="step"><FlagSVG /></span>
      </div>
    );
  }
}

Stepper.propTypes = {
  paymentRequired: PropTypes.bool.isRequired,
  giftIncluded: PropTypes.bool.isRequired,
  maxStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired
}

export default Stepper;
