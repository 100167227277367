import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Address from './Address';

class Addresses extends Component {
  constructor(props) {
    super(props);
  }

  addAddress = (event) => {
    event.preventDefault()
    this.props.addAddress({
      edit: true,
      line1: "",
      line2: "",
      line3: "",
      line4: "",
      salutation: "",
      first_name: "",
      last_name: "",
      addition: "",
      street: "",
      street_number: "",
      zip_code: "",
      city: "",
      country: "DE",
      is_parcel_station: false,
      can_change: this.props.withParcelStations
    })
  }

  delete = (addressId) => {
    return (event) => {
      event.preventDefault()
      event.stopPropagation()
      if (addressId) {
        $.ajax({
          url: `/bestellung/addresses/${addressId}.json`,
          type: "DELETE"
        }).done((data) => {
          this.props.update(data)
        })
      }
    }
  }

  save = (event, address, persistent, callback) => {
    if (address.id) {
      $.ajax({
        url: `/bestellung/addresses/${address.id}.json`,
        type: "PATCH",
        data: {address: address, persistent: persistent}
      }).done((data) => this.props.choose(address.id)(event)).fail(this.props.markErrors(address))
    } else {
      $.ajax({
        url: `/bestellung/addresses.json`,
        type: "POST",
        data: {address: address, persistent: persistent}
      }).done((data) => {this.props.choose(data.address.id)(event)}).fail(this.props.markErrors(address))
    }
  }

  render() {
    return (
      <div className="address-list">
        <div className="row">
          {
            this.props.address && !this.props.addresses.map((address) => address.id).includes(this.props.address.id) ?
              <Address key={`address_${this.props.address.id}`}
                       address={this.props.address}
                       delete={this.delete(this.props.address.id)}
                       editable={this.props.editable}
                       editMode={this.props.address.edit}
                       guest={this.props.guest}
                       reset={this.props.reset}
                       save={this.save}
                       selected={true} /> : ""
          }
          {
            this.props.addresses.map((address, index) => {
              if (address.is_parcel_station && !this.props.withParcelStations) {
                return ""
              } else {
                const selected = this.props.address && address.id == this.props.address.id
                return <Address key={`address_${address.id}`}
                                address={address}
                                choose={this.props.choose(address.id)}
                                delete={this.delete(address.id)}
                                editable={ this.props.editable }
                                guest={this.props.guest}
                                reset={this.props.reset}
                                save={this.save}
                                selected={selected} />
              }
            })
          }
        </div>
        {
          (!this.props.address || this.props.address.id) ?
            <div className="row">
              <div className="col-md-4">
                <div className="card add">
                  <a href="#"  onClick={this.addAddress}>
                    <i className="fas fa-plus" />
                    neue Adresse hinzufügen
                  </a>
                </div>
              </div>
            </div> : ""
        }
        { this.props.disabled ? <div className="disabled" /> : ""}
      </div>
    );
  }
}

Addresses.propTypes = {
  address: PropTypes.object,
  addAddress: PropTypes.func.isRequired,
  addresses: PropTypes.array.isRequired,
  choose: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  guest: PropTypes.bool.isRequired,
  markErrors: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  withParcelStations: PropTypes.bool.isRequired
}

export default Addresses;
