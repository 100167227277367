import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckmarkSVG from './../../../images/checkmark.svg';
import CartPlusSVG from './../../../images/cart-plus-fill.svg';
import CrossSVG from './../../../images/cross.svg';
import ReactTooltip from 'react-tooltip';

class CartItem extends Component {
  constructor(props) {
    super(props);
  }

  giftClassName = () => {
    if (this.props.item.gift) {
      return 'gift'
    } else {
      return ''
    }
  }

  updateItem = (attr) => {
    return (value) => {
      return (event) => {
        event.preventDefault();
        this.props.updateItem(attr)(value)
      }
    }
  }

  renderDelivery = () => {
    if (this.props.item.gift && !this.props.item.voucher_code) {
      return  <React.Fragment>
                <div className="radio">
                  <label onClick={this.updateItem('as_voucher')(true)}>
                    <input type="radio" name={`as_voucher_${this.props.item.id}`} checked={!!this.props.item.as_voucher} readOnly={true} />
                    <span><span /></span>
                    Als Gutschein
                  </label>
                <div className="radio">
                </div>
                  <label onClick={this.updateItem('as_voucher')(false)}>
                    <input type="radio" name={`as_voucher_${this.props.item.id}`} checked={!this.props.item.as_voucher} readOnly={true} />
                    <span><span /></span>
                    Direkt liefern
                  </label>
                </div>
              </React.Fragment>
    } else if (this.props.item.donated_box) {
      return <div className="active">
                Als Gutschein
                <CheckmarkSVG />
              </div>
    } else {
      return  <div className="active">
                Direkt liefern
                <CheckmarkSVG />
              </div>
    }
  }

  renderVersions = () => {
    if (this.props.item.as_voucher) {
      return 'Abhängig vom Einlösedatum'
    }
    if (this.props.item.available_versions.length > 1) {
      return  <div>
                { this.props.item.available_versions.map((version) => {
                  return  <div key={`radio_${this.props.item.id}_${version.id}`} className="radio">
                            <label onClick={this.updateItem('starting_version_id')(version.id)}>
                              <input type="radio"
                                     name={`starting_version_id_${this.props.item.id}`}
                                     checked={version.id == this.props.item.starting_version_id}
                                     readOnly={true} />
                              <span><span /></span>
                              Ab {version.description}
                            </label><br/>
                            <span>{version.delivery_from_description}</span>
                          </div>
                })}
              </div>
    } else if (this.props.item.available_versions.length == 1) {
      return  <div>
                Ab {this.props.item.available_versions[0].description}<br/>
                {this.props.item.available_versions[0].delivery_from_description}
              </div>
    }
  }

  renderImmediateDelivery = () => {
    if (this.props.item.immediate_delivery_version) {
      let checked = (this.props.item.starting_version_id == this.props.item.immediate_delivery_version.id)
      return  <div className="info radio">
                Du kannst es kaum abwarten?<br/>
                Dann wähle folgende Option:
                <label onClick={this.updateItem('starting_version_id')(checked ? this.props.item.available_versions[0].id : this.props.item.immediate_delivery_version.id)}>
                  <input type="checkbox"
                         checked={checked}
                         readOnly={true} />
                  <span><CheckmarkSVG /></span>
                  Ich möchte vorab und zusätzlich die { this.props.item.immediate_delivery_version.description } erhalten.<br/>
                </label>
                <span>{ this.props.item.immediate_delivery_version.delivery_from_description }</span>
              </div>
    }
  }

  renderDates = () => {
    if (this.props.item.delivery_date_selectable) {
      return  <React.Fragment>
                { this.props.item.delivery_dates.map((date) => {
                  return  <div className="radio" key={`delivery_date_${this.props.item.id}_${date.value}`}>
                            <label onClick={this.updateItem('delivery_on')(date.value)}>
                              <input type="radio" name={`delivery_date_${this.props.item.id}`} readOnly={true} checked={this.props.item.delivery_on == date.value} />
                              <span><span /></span>
                              {date.label}
                            </label>
                          </div>
                })}
              </React.Fragment>
    } else if (this.props.item.available_versions.length == 0) {
      return  <div>
                { this.props.item.delivery_text }
              </div>
    }
  }

  render() {
    return (
      <tr>
        <td className={this.giftClassName()}>
          <span><img src={this.props.item.image_src} alt={this.props.item.offer_title} /></span>
          <div>
            <p>{this.props.item.offer_title}</p>
            { this.props.item.noozie_points ? <p>{this.props.item.noozie_points}</p> : '' }
          </div>
        </td>
        <td>
          <h5 className="mobile">Lieferung</h5>
          { this.renderDelivery()}
        </td>
        <td>
          <h5 className="mobile">Versand</h5>
          { this.renderVersions() }
          { this.renderImmediateDelivery() }
          { this.renderDates() }
        </td>
        <td className="add">
          {
            this.props.item.surprise_box ? '' : <a href="#" onClick={this.props.duplicateItem} data-tip="nochmal in den Warenkorb legen">
                                                  <CartPlusSVG/>
                                                  <ReactTooltip />
                                                  <div className="mobile">Nochmal hinzufügen</div>
                                                </a>

          }
        </td>
        <td>
          <h5 className="mobile">Preis</h5>
          {this.props.item.price.split("\n").map((price_part, index) => {
            return <React.Fragment key={index}>{price_part}<br/></React.Fragment>
          })}
        </td>
        <td className="remove">
          <a href="#" onClick={this.props.deleteItem}>
            <CrossSVG data-tip="Löschen" />
            <ReactTooltip />
            <div className="mobile">Artikel löschen</div>
          </a>
        </td>
      </tr>
    );
  }
}

CartItem.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  duplicateItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  updateItem: PropTypes.func.isRequired
}

export default CartItem;
