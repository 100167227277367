import React, { Component } from 'react';
import ShoppingCartSVG from '../../images/shopping-cart.svg';
import FormattedMoney from './FormattedMoney';
import totalPrice from '../../utilities/shop_calculations';
import SubscriberDiscountContext from './SubscriberDiscountContext';

class ShoppingCartIcon extends Component {

  render() {
    return (
      <div>
        <ShoppingCartSVG />
        <SubscriberDiscountContext.Consumer>
          { (subscriberDiscounts) => (
            <span>
                <FormattedMoney priceCents={totalPrice(this.props.cart, subscriberDiscounts)} />
            </span>
          )}
        </SubscriberDiscountContext.Consumer>
      </div>
    )
  }
}

export default ShoppingCartIcon;
