import React, { Component } from 'react';
import CookieConfiguration from './CookieConfiguration';
import CookieExplanation from './CookieExplanation'

class CookieMessage extends Component {
  constructor(props) {
    super(props);
    const cookieConfiguration = $.cookie('cookie_configuration')
    this.state = {
      configuration: cookieConfiguration == '' ? null : JSON.parse(cookieConfiguration) ,
    }
  }

  componentDidMount = () => {
    if (!this.state.configuration) {
      this.deleteAllCookies()
    }
  }

  deleteAllCookies = () => {
    document.cookie.split(";").forEach(function(c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/");
    });
  }

  allowAllCookies = (event) => {
    event.preventDefault()
    this.storeCookieConfiguration('["essential","marketing"]')
    location.reload()
  }

  configureMinimal = (event) => {
    event.preventDefault()
    this.setState({configuration: ['essential']})
    this.storeCookieConfiguration('["essential"]')
  }

  storeCookieConfiguration = (configuration) => {
    $.cookie('cookie_configuration', configuration, { secure: (location.protocol == 'https:'), domain: location.host.split(':')[0], expires: 90 });
  }

  allowEssentialCookies = (event) => {
    this.deleteAllCookies()
    this.configureMinimal(event)
    location.reload()
  }

  render() {
    if (this.state.configuration) {
      return <CookieConfiguration configuration={this.state.configuration} allowAllCookies={this.allowAllCookies} allowEssentialCookies={this.allowEssentialCookies} />
    } else {
      return <CookieExplanation allowAllCookies={this.allowAllCookies} configure={this.configureMinimal} />
    }
  }
}

export default CookieMessage;
