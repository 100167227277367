import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

class Info extends Component {
  render() {
    if (this.props.children) {
      return <div className="toggle-info">
        {this.props.infoTitle}
        <i className="fas fa-question-circle fa-lg"></i>
        <div className="info-details">
          {this.props.children}
        </div>
      </div>
    } else {
      return ''
    }
  }
}

Info.propTypes = {
  infoTitle: PropTypes.string
}

class Errors extends Component {
  render() {
    return <div className="errors">
      {this.props.errors.map((error, index) => <p key={`error_${index}`}>{error}</p>)}
    </div>
  }
}

Errors.propTypes = {
  errors: PropTypes.array.isRequired
}

class Select extends Component {
  render() {
    return <label className={this.props.column ? this.props.column : null}>
      <div className="form-group">
        <label>{this.props.name}</label>
        <ReactSelect placeholder={this.props.placeholder}
          options={this.props.options}
          value={this.props.value}
          onChange={this.props.onChange}
          isClearable={this.props.isClearable}
          isSearchable={this.props.isSearchable}
          isDisabled={this.props.isDisabled}
          isMulti={this.props.isMulti} />
      </div>
    </label>

  }
}

Select.propTypes = {
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  column: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}

class TextField extends Component {
  render() {
    return <label>
      {this.props.children ? <Info infoTitle={this.props.name}>{this.props.children}</Info> : this.props.name}
      <input type="text" className={this.props.inputClass} onChange={this.props.handleChange} value={this.props.value} placeholder={this.props.placeholder} />
      {this.props.errors && <Errors errors={this.props.errors} />}
    </label>
  }
}

TextField.propTypes = {
  errors: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  inputClass: PropTypes.string,
  noColumn: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired
}

export { TextField, Select }
