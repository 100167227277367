export function reportVirtualPageRequest(step) {
  const stepsToReport = {
    1: {
      'virtualPageURL': '/checkout_start/',
      'virtualPageName': 'jetzt_bestellen'
    },
    2: {
      'virtualPageURL': '/warenkorb',
      'virtualPageName': 'warenkorbuebersicht'
    },
    3: {
      'virtualPageURL': '/address',
      'virtualPageName': 'address'
    },
    4: {
      'virtualPageURL': '/payment',
      'virtualPageName': 'payment'
    },
    5: {
      'virtualPageURL': '/overview',
      'virtualPageName': 'final_overview'
    },
  }

  return () => {
    dataLayer.push({
      'event': 'VirtualPageview',
      'contentGroup1': 'bestellung',
      ...stepsToReport[step]
    })
  }
}

/** <!-- Add Cart Tracking --> */
function productFromCartItem(cartItem) {
  const category = cartItem.is_subscription == true ? 'Abo-Box' : 'Themen-Box';
  let priceCents = cartItem.allow_subscriber_discount == true && cartItem.acts_as_subscriber ? cartItem.subscriber_price_cents : cartItem.price_cents_without_subscriber_discount;
  priceCents = parseFloat(priceCents / 100).toFixed(2);
  return [
    {
      'name': cartItem.name,
      'id': cartItem.id,
      'price': priceCents,
      'category': category,
      'quantity': 1
    }, priceCents, category
  ]
}


export function addToCartTracking(cartItem) {
  const [priceCents, category, product] = productFromCartItem(cartItem)

  dataLayer.push({
    event: 'eec.detail',
    'ecommerce': {
      'detail': {
        'products': [{
          'name': cartItem.name,
          'id': cartItem.id,
          'price': priceCents,
          'category': category,
        }]
      }
    }
  });

  dataLayer.push({
    'event': 'eec.addToCart',
    'ecommerce': {
      'currencyCode': 'EUR',
      'add': {
        'products': [product]
      }
    }
  });
}

export function checkoutCartTracking(cartItems) {
  const products = cartItems.map((cartItem) => productFromCartItem(cartItem))[0]
  dataLayer.push({
    event: 'eec.checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: 1
        },
        products: products
      }
    }
  })
}

/** <!-- Remove Cart Tracking --> */
export function removeCartTracking(cartItem) {
  const [priceCents, category, product] = productFromCartItem(cartItem)

  dataLayer.push({
    'event': 'eec.removeFromCart',
    'ecommerce': {
      'currencyCode': 'EUR',
      'remove': {
        'products': [product]
      }
    }
  });
}
